.bckg-header {
  background: white;
}
.img1 {
  width: 150px;
  height: 150px;
  background-image: url('./assets/images/ISABEL-GPI.webp');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  background-color: #0F47AF;
}


.img2 {
  width: 150px;
  height: 150px;
  background-image: url('./assets/images/Contadora-General.webp');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  background-color: #0F47AF;
}
