.slide {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40em;
  color: #000;
  text-align: center;
  background: #364d79;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.first-slide {
  background-image: url('https://image.freepik.com/foto-gratis/imbabura-vulcano_1958-55.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.second-slide {
  background-image: url('https://image.freepik.com/foto-gratis/vista-aerea-grafico-analisis-datos-negocios_53876-13390.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.third-slide {
  background-image: url('https://image.freepik.com/foto-gratis/apreton-manos-personas-negocios-trabajo-equipo-fusion-adquisicion-negocios_1645-143.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.fourth-slide {
  background-image: url('https://image.freepik.com/foto-gratis/vista-cercana-dos-estudiantes-universitarios-que-consultan-sobre-su-tesis-sobre-mesa-madera_67155-7044.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.background-ethic {
  background-image: url('../../assets/svg/ethic.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.background-inst {
  background-image: url('../../assets/svg/insti.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.background-edu {
  background-image: url('../../assets/svg/edu.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-contacto {
  background-image: url('../../assets/svg/contacto.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-little-black {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.349);
}
.background-little-light {
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
}

.title {
  margin: 0px 20px;
  font-weight: bold;
}
